//Firebase
import { FirebaseAuth, StyledFirebaseAuth } from 'react-firebaseui';
import { FirebaseApp, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { GoogleAuthProvider } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { firebaseUser } from '../App';
import { Link } from 'react-router-dom';


const firebaseConfig = {
    apiKey: "AIzaSyB6MPKWrMmMGuNsl8GkHxznqG-lca_Je34",
    authDomain: "apollo-sweetheart.firebaseapp.com",
    databaseURL: "https://apollo-sweetheart-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "apollo-sweetheart",
    storageBucket: "apollo-sweetheart.appspot.com",
    messagingSenderId: "881996568266",
    appId: "1:881996568266:web:6f73b801bd7510aa160633",
    measurementId: "G-51449TR79N"
};
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        new GoogleAuthProvider().providerId
    ],


};

const LoginPage = () => {
    const [user, setUser] = useAtom(firebaseUser)
    const [LoginStateLoaded, setLoginStateLoaded] = useState(false)
    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(newuser => {
            setLoginStateLoaded(true)
            setUser(newuser);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (LoginStateLoaded) {
        return <FirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    }
    return <></>
}
export default LoginPage