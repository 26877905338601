import { getAuth } from "@firebase/auth"
import { useSetAtom } from "jotai"
import { Link } from "react-router-dom"
import { firebaseUser } from "../App"

export function LogOutButton() {
    const auth = getAuth()
    const setUser = useSetAtom(firebaseUser)

    function SignOut() {
        auth.signOut().then(
            () => { setUser(null) }
        );
    }
    return <Link onClick={SignOut} to="/Login">Sign-out</Link>
}