import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import { getAuth } from "firebase/auth"
import { atom, useAtom, useAtomValue } from 'jotai'

const App = () => {
  const user = useAtomValue(firebaseUser)


  return (
    <BrowserRouter>
      {!!user ?
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/cummies" element={<>cummies</>} />
        </Routes>
        :
        <LoginPage />}
    </BrowserRouter>
  )

}
export default App;

//atoms
export const firebaseUser = atom(getAuth().currentUser)